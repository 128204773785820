import logo from "./logo.svg";
import "./App.css";
import React, { useState } from "react";
import Solana from "solana/main";
import Ethereum from "ethereum/main";
function App() {
  const [network, setNetwork] = useState('ethereum');
  const switchNetwork = () => {
    network == 'ethereum' ? setNetwork('solana') : setNetwork('ethereum')
  }
  return (
    <div className="App">
      <button onClick={switchNetwork}>Use {network === 'ethereum' ? 'solana' : 'ethereum'}</button>
      {network == 'ethereum' ? 
        <Ethereum/> :
        <Solana/>
      }
    </div>
  );
}

export default App;
