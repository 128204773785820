import SubmitButtonEth from "ethereum/components/SubmitButtonEth";
import React, { useState } from "react";
import SubmitButtonSol from "solana/SubmitButtonSol";
export type ConenctWalletPromptProps = {
  // any props that come into the component
};
const ConenctWalletPrompt = ({}: ConenctWalletPromptProps) => {
  return <div className="text-2xl">Connect your wallet to get started</div>;
};

export default ConenctWalletPrompt;
