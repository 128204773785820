import { http, createConfig } from 'wagmi'
import { localhost, mainnet, sepolia } from 'wagmi/chains'

console.log("HERE " + JSON.stringify(localhost))

// const rpcVal = {"default":{"http":["http://127.0.0.1:8545"]}};
// @ts-ig
export const config = createConfig({
  chains: [mainnet, sepolia, localhost],
  transports: {
    [mainnet.id]: http(),
    [sepolia.id]: http(),
    [localhost.id]: http(),
  },
})