import SubmitButtonEth from "ethereum/components/SubmitButtonEth";
import React, { useState } from "react";
import SubmitButtonSol from "solana/SubmitButtonSol";
export type DepositModalProps = {
  network: string;
  // any props that come into the component
};
const DepositModal = ({ network }: DepositModalProps) => {
  const [amount, setAmount] = useState("");
  const [token, setToken] = useState("");

  const handleAmountChange = (value: any) => {
    if (value >= 0) setAmount(value);
  };
  const handleTokenChange = (value: any) => {
    setToken(value);
  };
  // const handleSubmit = () => {
  //   submitTransaction(amount, token);
  // };
  return (
    <div className="h-24 bg-red-200 text-2xl">
      <label className="text-sm">
        Token:
        <input
          value={token}
          onChange={(e) => handleTokenChange(e.target.value)}
        />
      </label>
      <label className="text-sm">
        Amount:
        <input
          value={amount}
          onChange={(e) => handleAmountChange(e.target.value)}
        />
      </label>
      <div>
        {network === "solana" ? (
          <SubmitButtonSol amount={amount} tokenAddres={token} />
        ) : (
          <SubmitButtonEth amount={amount} tokenAddress={token} />
        )}
      </div>
    </div>
  );
};

export default DepositModal;
