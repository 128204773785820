import SubmitButtonEth from "ethereum/components/SubmitButtonEth";
import React, { useState } from "react";
import SubmitButtonSol from "solana/SubmitButtonSol";
import WithdrawModal from "./WithdrawModal";
export type BalancesProps = {
  balances: any;
  // any props that come into the component
};
const Balances = ({ balances }: BalancesProps) => {
  return (
    <div className="">
      {balances.map((balance, index) => (
        <div className="flex w-1/2 justify-around">
          <div>{balance.token}</div>
          <div>{balance.balance}</div>
          <button>Withdraw</button>
        </div>
      ))}
      <WithdrawModal showHideModal={undefined} />
    </div>
  );
};

export default Balances;
