import SubmitButtonEth from 'ethereum/components/SubmitButtonEth'
import React, { useEffect, useState } from 'react'
import { signMessage } from '@wagmi/core'
import SubmitButtonSol from 'solana/SubmitButtonSol'
import { config } from 'ethereum/config'
import { useAccount } from 'wagmi'
import signSolMessage from 'solana/utils/signSolMessage'
import { useWallet } from '@solana/wallet-adapter-react'
import bs58 from 'bs58'
import { PublicKey } from '@solana/web3.js'
export type VerificationModalProps = {
  network: string
  hash: string
  userName: string
  address: string
  // any props that come into the component
}
const VerificationModal = ({
  network,
  hash,
  userName,
  address,
}: VerificationModalProps) => {
  const { signMessage: signMsg, publicKey } = useWallet()
  const message =
    'Welcome to the Gabi Gaming App. Sign the message to get started'
  const verifyUser = async (
    result: any,
    network: string,
    publicKey?: PublicKey,
  ) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body:
        network === 'ethereum'
          ? JSON.stringify({
              signature: result,
              address: address,
              network: 'ethereum',
              urlHash: hash,
            })
          : JSON.stringify({
              publicKey: publicKey.toBase58(),
              signature: bs58.encode(result),
              network: 'solana',
              urlHash: hash,
            }),
    }
    // console.log("THE TX WE SHOULD SEND IS "+tx)
    const response = await fetch(
      `http://${process.env.REACT_APP_API_URL}/verifySignature`,
      requestOptions,
    )
    const data = await response.json()
  }
  const handleSignMessage = async () => {
    console.log('HASH IS ' + hash)
    if (network == 'ethereum') {
      const result = await signMessage(config, {
        //@ts-ignore
        account: address,
        message: message,
      })
      await verifyUser(result, 'ethereum')
    } else if (network == 'solana') {
      const signedMessage = await signSolMessage(message, signMsg)
      console.log(`MEssaage digned man ${JSON.stringify(signedMessage)}`)
      console.log('MESSAGE SIGNED ' + signedMessage)
      await verifyUser(signedMessage, 'solana', publicKey)
    }
  }
  return (
    <div className="h-24 bg-green-200 text-2xl">
      <div> Welcome {userName} </div>
      <div>
        <p>
          Please use your wallet to sign the message and link your wallet to
          your account
        </p>
        <button onClick={handleSignMessage}>Sign Message</button>
      </div>
    </div>
  )
}

export default VerificationModal
