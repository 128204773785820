import {
  createAssociatedTokenAccountInstruction,
  createTransferInstruction,
  getAccount,
  getAssociatedTokenAddress,
} from '@solana/spl-token'
import { WalletNotConnectedError } from '@solana/wallet-adapter-base'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import Balances from 'components/Balances'
import ConenctWalletPrompt from 'components/ConnectWalletPrompt'
import DepositModal from 'components/DepositModal'
import Dropdown from 'components/Dropdown'
import VerificationModal from 'components/VerificationModal'
import { sign } from 'crypto'
import React, { useCallback, useEffect, useState } from 'react'

export type SolMainProps = {
  // any props that come into the component
}
const SolMain = ({}: SolMainProps) => {
  const { connection } = useConnection()
  const { publicKey, sendTransaction } = useWallet()
  // const {account} = getAccount();
  const [hash, setHash] = useState('')
  const [isVerified, setIsVerified] = useState(false)
  const [userName, setUserName] = useState('')
  const [pubKeyString, setPubKeyString] = useState('')
  const [balances, setBalances] = useState([])
  //   console.log("KEY " + publicKey.toBase58());
  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search)
    // console.log(searchParams.get("hash")); // outputs "m2-m3-m4-m5"
    console.log('SET HASH MAN')
    //@ts-ignore
    setHash(searchParams.get('hash'))
    if (publicKey && publicKey.toBase58()) {
      //@ts-ignore
      setPubKeyString(publicKey.toBase58())
      //@ts-ignore
      getUserNameFromAddress(publicKey.toBase58(), searchParams.get('hash'))
    }
  }, [publicKey])
  async function getUserNameFromAddress(address: string, hash: string) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        address: address,
        network: 'solana',
        hash: hash,
      }),
    }
    // console.log("THE TX WE SHOULD SEND IS "+tx)
    const response = await fetch(
      `http://${process.env.REACT_APP_API_URL}/userData`,
      requestOptions,
    )
    const data = await response.json()
    if (data.userData.isVerified) {
      setBalances(data.userData.balances)
      setIsVerified(true)
      console.log('BALNCES UPDATE ' + JSON.stringify(data.userData.balances))
    }
  }

  return (
    <div>
      {publicKey ? (
        <>
          {isVerified ? (
            <>
              <DepositModal network={'solana'} />
              <Balances balances={balances} />
              {/* <>
                <div className="flex flex-col items-center justify-center">
                  <Dropdown />
                  <input
                    value={"hello"}
                    // onChange={(e) => handleTokenChange(e.target.value)}
                  />
                </div>
                <div className="flex justify-center">
                  <button className="bg-red-200 px-4 rounded-l-lg ">
                    Deposit
                  </button>{" "}
                  <button className="bg-green-200 px-4 rounded-r-lg">
                    Withdraw
                  </button>{" "}
                </div>
              </> */}
            </>
          ) : (
            <>
              <VerificationModal
                network={'solana'}
                hash={hash}
                userName={userName}
                address={pubKeyString}
              />
              {/* {1 == 1 ? (
                <VerificationModal
                  network={'solana'}
                  hash={hash}
                  userName={userName}
                  address={pubKeyString}
                />
              ) : (
                <>
                  {' '}
                  Sign in with your wallet or visit the verification bot on
                  telegram to get started{' '}
                </>
              )} */}
            </>
          )}
        </>
      ) : (
        <ConenctWalletPrompt />
      )}
    </div>
  )
}

export default SolMain
